<template>
  <div class="list-container">

    <!-- Page Header Section -->
    <div class="d-flex justify-content-between">
      <h3 class="title pb-4">
        {{staticText.baoValue}}
      </h3>
    </div>

    <div class="d-flex justify-content-between mb-3">
      <b-button
        @click="showModal()"
        class="px-4 my-auto"
        variant="primary"
      >
        <img src="/img/icons/light-plus-icon.svg"/>
        {{ staticText.addBaoValueMapping }}
      </b-button>
    </div>

    <!-- Success Message -->
    <div class="table-wrapper">
      <div v-for="(item, index) in baoValueMappings"
           :key="index"
           class="table-responsive"
      >
        <b-table :items="item"
                 :fields="getFields()"
                 hover
        >
          <template v-slot:cell(action)="data">
            <div class="d-flex justify-content-between">
              <b-btn
                :id="'bao-value-edit-btn-202103180111-'+data.item.id"
                variant="secondary"
                class="action-button"
                @click="showModal(data.item)"
              >
                <img src="/img/icons/edit-icon.svg"/>
              </b-btn>

              <bao-delete-button
                :id="'bao-value-delete-btn-202103180111-'+data.item.id"
                :url="'/api/baovaluemapping/'+data.item.id"
                @deleted="getData"
              >
              </bao-delete-button>
            </div>
          </template>
        </b-table>
      </div>
    </div>

    <b-modal id="create-bao-value-modal"
             v-model="createDialogOpen"
             title="Create a new bao value mapping"
             ok-title="Create"
             size="lg"
             @ok="createNewMapping(selectedMapping)"
    >
      <div>
        <div class="d-flex justify-content-between w-100 my-1">
          <span class="w-25 my-auto">
            {{ staticText.playbookLabel }}:
          </span>

          <multiselect v-model="playbook"
                       :options="talkscriptList"
                       :showLabels="false"
                       label="name"
                       track-by="id"
                       placeholder="Select a playbook"
                       @select="getTalkscriptItems"
          ></multiselect>
        </div>

        <div class="d-flex justify-content-between w-100 my-1">
          <span class="w-25 my-auto">
            {{ staticText.playbookItemLabel }}:
          </span>

          <multiselect v-model="playbookitem"
                       :options="talkscriptItemList"
                       :showLabels="false"
                       label="name"
                       track-by="id"
                       placeholder="Select a playbook item"
                       @select="getAnswerList"
          ></multiselect>
        </div>

        <div class="d-flex justify-content-between w-100 my-1">
          <span class="w-25 my-auto">
            {{ staticText.answerLabel }}:
          </span>

          <multiselect v-model="answer"
                       :options="answerList"
                       :showLabels="false"
                       label="name"
                       track-by="id"
                       placeholder="Select a playbook"
          ></multiselect>
        </div>

        <div class="d-flex justify-content-between w-100 my-1">
          <span class="w-25 my-auto">
            {{ staticText.valueChangeLabel }}:
          </span>

          <b-form-input v-model="valueChange"
                        type="number"
          ></b-form-input>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios"
import Multiselect from "vue-multiselect"
import BaoDeleteButton from "@/apps/base/BaoDeleteButton"
import { mapGetters } from "vuex"

export default {
  name: "BaoValueList",
  components: {
    Multiselect,
    BaoDeleteButton
  },
  data () {
    return {
      axios,
      staticTextDefault: {
        playbookLabel: "Playbook",
        playbookItemLabel: "Playbook Item",
        answerLabel: "Answer",
        valueChangeLabel: "Value Change",
        editLabel: "Edit",
        addBaoValueMapping: "Add bao value mapping",
        baoValue: "bao Value"
      },
      baoValueMappings: {},
      talkscriptList: [],
      talkscriptItemList: [],
      answerList: [],
      createDialogOpen: false,
      selectedMapping: {},
      playbook: null,
      playbookitem: null,
      answer: null,
      valueChange: 5,
      saving: false
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      isUserAuthenticated: "auth/isUserAuthenticated"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    showModal (item = null) {
      this.createDialogOpen = true
      this.resetMappingData(item)
    },
    resetMappingData (item = null) {
      if (!item) {
        this.playbook = null
        this.playbookitem = null
        this.answer = null
        this.valueChange = 5
        return
      }
      this.selectedMapping = item

      this.axios.get("/api/talkscripts/" + item.talkscript).then(response => {
        this.playbook = response.data
      })
      this.getTalkscriptItems({ id: item.talkscript }).then(response => {
        const selectedPlaybookItems = response.filter(talkscriptItem => talkscriptItem.id === item.talkscript_item)
        this.playbookitem = selectedPlaybookItems[0]
        if (selectedPlaybookItems && selectedPlaybookItems.length > 0) {
          this.getAnswerList(selectedPlaybookItems[0])
          this.answer = selectedPlaybookItems[0].answer_choices.filter(
            answerChoice => answerChoice.name === item.answer_choice
          )[0]
        }
      })
    },
    createNewMapping (newMappingData) {
      if (this.saving) return
      this.saving = true
      let method = "post"
      let url = "/api/baovaluemapping"
      if (newMappingData.id) {
        method = "put"
        url += "/" + newMappingData.id
      }
      const data = {
        talkscript: this.playbook.id,
        talkscript_item: this.playbookitem.id,
        answer_choice: this.answer.name,
        value_change: this.valueChange
      }
      this.axios[method](url, data).then(() => {
        this.getData()
        this.selectedMapping = {}
        this.saving = false
      })
    },
    getAnswerList (talkscriptItem) {
      this.answerList = talkscriptItem.answer_choices
    },
    getTalkscriptItems (talkscript) {
      return new Promise((resolve, reject) => {
        this.axios.get(
          "/api/talkscriptitems/get_min_view/?parents__talkscript=" + talkscript.id + "&answerchoice__isnull=false"
        ).then(response => {
          this.talkscriptItemList = response.data.results
          resolve(response.data.results)
        }).catch(reject)
      })
    },
    getData () {
      this.axios.get("/api/baovaluemapping").then(response => {
        this.mapData(response.data)
      })

      this.axios.get("/api/talkscripts/get_min_view?type=default&active=true").then(response => {
        this.talkscriptList = response.data
      })
    },
    mapData (data) {
      const mappingList = data.results
      const result = {}
      for (const i in mappingList) {
        const mapping = mappingList[i]
        if (!result[mapping.name]) {
          result[mapping.name] = []
        }
        result[mapping.name].push(mapping)
      }
      this.baoValueMappings = result
    },
    getFields () {
      return [
        {
          key: "talkscript_name",
          label: this.staticText.playbookLabel,
          sortable: true,
          sortDirection: "desc",
          tdClass: "align-middle"
        },
        {
          key: "talkscript_item_name",
          label: this.staticText.playbookItemLabel,
          sortable: true,
          sortDirection: "desc",
          tdClass: "align-middle"
        },
        {
          key: "answer_choice",
          label: this.staticText.answerLabel,
          sortable: true,
          sortDirection: "desc",
          tdClass: "align-middle"
        },
        {
          key: "value_change",
          label: this.staticText.valueChangeLabel,
          sortable: true,
          sortDirection: "desc",
          tdClass: "align-middle"
        },
        {
          key: "action",
          label: "",
          tdClass: "align-middle"
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.table-wrapper {
  border-radius: 20px;
  background-color: $white80;
  padding-bottom: 1px;
}
</style>
